import React, { Component } from "react";
import ScorecardReportFilter from "../ScorecardReportFilter/ScorecardReportFilter";
import utils from "../../../utils";
import EndpointConstant from "../../../constants/EndpointConstant";
import {
  ScorecardQuestionsetName,
  ScorecardQuestionstatus,
  ScorecardProduct,
  ScorecardChannel,
  ScorecardStatus,
  ScorecardCountByDataset,
  ScorecardCountByAssociatedTeam,
  ScorecardCountByCCAdmin,
} from "../ScorecardReports/ScorecardReportCharts/ScorecardReportCharts";
import PropTypes from "prop-types";

/**
 * A class that filter gims report
 **/
class ScorecardReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChart: false,
      scorecardReports: {},
    };
    this.getScorecardReport = this.getScorecardReport.bind(this);
  }

  /*
   * Hook to call the api to pass the data
   * for d3 to generate the charts
   */
  componentDidMount() {
    this.getScorecardReport(null);
  }

  getScorecardReport(filterData) {
    let self = this;
    let data = filterData ? filterData : {};
    // data['report'] = true;
    // utils.makeRequest(this, EndpointConstant.SCORECARD_REPORT, data, function (result) {
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_REPORT_FROM_FILE,
      data,
      function (result) {
        if (result.error === "false" || result.error === false) {
          self.loading = false;
          self.setState({ scorecardReports: result, showChart: true });
        }
      }
    );
    // var test = { result: { error: false, "questionsetName": { "sample question1": 5, "Test q": 1, "test": 1, "Questionaries test": 1, "undefined": 1, "cdcdCDCC": 1, "Arun'S Questionnaire": 2 }, "questionStatus": { "pass": 6, "fail": 1, "": 1, "undefined": 4 }, "product": { "blincyto": 2, "aimovig": 4, "aranesp": 2, "undefined": 4 }, "status": { "Assigned": 8, "Completed": 4 }, "channel": { "Phone": 1, "": 11 }, "callCenterAdmin": { "Boughida, Dounia": 2, "Zotter-Tufaro, Caroline": 2, "Travis, Charles-PPD": 2, "Li, Rachel": 2, "El Soudany, Mey": 2, "Pyndt, Christina": 2, "Larkin, Susan": 2, "Molnar, Edit": 2, "Svinka, Jasmin": 2, "Santos Albuquerque, Julian": 2, "Fatykhova, Nailia-4Sigma": 2, "Sobhani Marani, Petra-ICT": 2 }, "associatedTeam": { "France MedInfo Call Center": 2, "Austria MedInfo": 2, "USA MedInfo Call Center Neuroscience": 1, "JAPAC MedInfo Call Center": 1, "Nordics and Baltics MedInfo": 1, "UK MedInfo": 1, "Hungary MedInfo": 1, "Brazil MedInfo": 1, "Germany MedInfo Call Center": 1, "Sweden MedInfo Call Center": 1 }, "dataset": { "BioconnectInteraction2021-05-31_05:20:21am": 1, "australia": 1, "BioconnectInteraction2021-05-24_06:01:37am": 5, "Dataset1_$1": 1, "RenameDataset": 2, "germany": 1, "test1": 1 } } };

    // this.setState({
    //     scorecardReports: test.result,
    //     showChart: true
    // })
  }

  render() {
    const { showChart, scorecardReports } = this.state;

    /*
     * scorecard count by product
     */
    const countByQuestionset = [];
    if (scorecardReports.hasOwnProperty("questionsetName")) {
      // console.log('hello', scorecardReports.questionsetName)
      let questionsetName = scorecardReports.questionsetName;
      let questionsetKey = Object.keys(questionsetName);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      // console.log('questionsetKey', questionsetKey)
      questionsetKey.map((data, i) => {
        return countByQuestionset.push({
          errorTitle: data ? data : "Unknown",
          count: questionsetName[data],
          color: color[i],
        });
      });
      // console.log("countByQuestionset", countByQuestionset);
    }
    /*
     * Subcase count by Owner Ends
     */

    /*
     * scorecard count by dataaset
     */
    const countByDataset = [];
    if (scorecardReports.hasOwnProperty("dataset")) {
      let dataset = scorecardReports.dataset;
      let datasetKey = Object.keys(dataset);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      datasetKey.map((data, i) => {
        return countByDataset.push({
          errorTitle: data ? data : "Unknown",
          count: dataset[data],
          color: color[i],
        });
      });
      // console.log("countByDataset", countByDataset);
    }
    /*
     * Subcase count by dataset Ends
     */

    /*
     * scorecard count by dataaset
     */
    const countByAssociatedTeam = [];
    if (scorecardReports.hasOwnProperty("associatedTeam")) {
      let associatedTeam = scorecardReports.associatedTeam;
      let associatedTeamKey = Object.keys(associatedTeam);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      associatedTeamKey.map((data, i) => {
        return countByAssociatedTeam.push({
          errorTitle: data ? data : "Unknown",
          count: associatedTeam[data],
          color: color[i],
        });
      });
      // console.log("countByAssociatedTeam", countByAssociatedTeam);
    }
    /*
     * Subcase count by Associated Team Ends
     */

    /*
     * scorecard count by dataaset
     */
    const countByCCAdmin = [];
    if (scorecardReports.hasOwnProperty("callCenterAdmin")) {
      let callCenterAdmin = scorecardReports.callCenterAdmin;
      let callCenterAdminKey = Object.keys(callCenterAdmin);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      callCenterAdminKey.map((data, i) => {
        return countByCCAdmin.push({
          errorTitle: data ? data : "Unknown",
          count: callCenterAdmin[data],
          color: color[i],
        });
      });
      // console.log("countByCCAdmin", countByCCAdmin);
    }
    /*
     * Subcase count by Associated Team Ends
     */

    /*
     * scorecard count by Question status
     */
    const countByQuestionstatus = [];
    if (scorecardReports.hasOwnProperty("questionStatus")) {
      let questionStatus = scorecardReports.questionStatus;
      let questionStatusKey = Object.keys(questionStatus);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      questionStatusKey.map((data, i) => {
        return countByQuestionstatus.push({
          errorTitle: data ? data : "Unknown",
          count: questionStatus[data],
          color: color[i],
        });
      });
    }
    /*
     * Subcase count by Question status Ends
     */

    /*
     * scorecard count by Question product
     */
    const countByProduct = [];
    if (scorecardReports.hasOwnProperty("product")) {
      let product = scorecardReports.product;
      let productKey = Object.keys(product);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      productKey.map((data, i) => {
        return countByProduct.push({
          errorTitle: data ? data : "Unknown",
          count: product[data],
          color: color[i],
        });
      });
    }
    /*
     * Subcase count by product Ends
     */

    /*
     * scorecard count by Channel
     */
    const countByChannel = [];
    if (scorecardReports.hasOwnProperty("channel")) {
      let channel = scorecardReports.channel;
      let channelKey = Object.keys(channel);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      channelKey.map((data, i) => {
        return countByChannel.push({
          errorTitle: data ? data : "Unknown",
          count: channel[data],
          color: color[i],
        });
      });
    }
    /*
     * Subcase count by Channel Ends
     */

    /*
     * scorecard count by Channel
     */
    const countByStatus = [];
    if (scorecardReports.hasOwnProperty("status")) {
      let status = scorecardReports.status;
      let statuslKey = Object.keys(status);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      statuslKey.map((data, i) => {
        return countByStatus.push({
          errorTitle: data ? data : "Unknown",
          count: status[data],
          color: color[i],
        });
      });
    }
    /*
     * Subcase count by Channel Ends
     */

    return (
      <div className="gims-charts">
        <div className="row">
          <div className="col-md-12">
            <ScorecardReportFilter
              getScorecardReportFilter={this.getScorecardReport}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionOne"
                  checked={this.props.sectionOne}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionOne">
              <h6 className="mt-3">Scorecard count by Questionset</h6>
              {showChart && (
                <ScorecardQuestionsetName
                  id="countByQuestionset"
                  data={countByQuestionset}
                  width={1000}
                  height={300}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionTwo"
                  checked={this.props.sectionTwo}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionTwo">
              <h6 className="mt-3">Scorecard count by Dataset</h6>
              {showChart && (
                <ScorecardCountByDataset
                  id="countByDataset"
                  data={countByDataset}
                  width={1050}
                  height={350}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionNine"
                  checked={this.props.sectionNine}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionNine">
              <h6 className="mt-3">Scorecard count by Created by Team</h6>
              {showChart && (
                <ScorecardCountByAssociatedTeam
                  id="countByAssociatedTeam"
                  data={countByAssociatedTeam}
                  width={1000}
                  height={300}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionFour"
                  checked={this.props.sectionFour}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionFour">
              <h6 className="mt-3">Scorecard count by Call Center Agent</h6>
              {showChart && (
                <ScorecardCountByCCAdmin
                  id="countByCCAdmin"
                  data={countByCCAdmin}
                  width={1000}
                  height={300}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 chart-pad-left pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionFive"
                  checked={this.props.sectionFive}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionFive">
              <h6 className="mt-3">Scorecard count by Question status</h6>
              {showChart && (
                <ScorecardQuestionstatus
                  id="countByQuestionstatus"
                  data={countByQuestionstatus}
                  width={450}
                  height={250}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>

          <div className="col-md-6 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionTen"
                  checked={this.props.sectionTen}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionTen">
              <h6 className="mt-3">Scorecard count by Intake Channel Type</h6>
              {showChart && (
                <ScorecardChannel
                  id="countByChannel"
                  data={countByChannel}
                  width={400}
                  height={250}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionSeven"
                  checked={this.props.sectionSeven}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionSeven">
              <h6 className="mt-3">Scorecard count by Product</h6>
              {showChart && (
                <ScorecardProduct
                  id="countByProduct"
                  data={countByProduct}
                  width={400}
                  height={250}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>

          <div className="col-md-6 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionEight"
                  checked={this.props.sectionEight}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionEight">
              <h6 className="mt-3">Scorecard count by Status</h6>
              {showChart && (
                <ScorecardStatus
                  id="countByStatus"
                  data={countByStatus}
                  width={400}
                  height={250}
                  dataReports={this.state.scorecardReports}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*
 * ScorecardReports propTypes checking
 * for checking props type
 */
ScorecardReports.propTypes = {
  showChecked: PropTypes.bool,
  sectionTen: PropTypes.bool,
  sectionFive: PropTypes.bool,
  sectionFour: PropTypes.bool,
  handleChange: PropTypes.func,
  sectionNine: PropTypes.bool,
  sectionTwo: PropTypes.bool,
  sectionOne: PropTypes.bool,
  sectionSeven: PropTypes.bool,
  sectionEight: PropTypes.bool,
};

export default ScorecardReports;
