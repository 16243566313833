import React, { Component } from "react";
import "./GimsReportChartLayout.css";
import utils from "../../../utils";
import EndpointConstant from "../../../constants/EndpointConstant";
import {
  GimsSubcaseCountOwnerTeam,
  GimsIntakeChannelCount,
} from "../GimsReportChartLayout/GimsReportCharts/GimsReportCharts";
import GimsReportFilter from "../GimsReportFilter/GimsReportFilter";
// import { object } from 'prop-types';
import PropTypes from "prop-types";

/*
 * A class to set the layouting the component
 * calling the component and data to show the charts
 */
class GimsReportChartLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChart: false,
      gimsModelReports: {},
    };
    this.getGimsReport = this.getGimsReport.bind(this);
    this.generateErrorTypeText = this.generateErrorTypeText.bind(this);
  }

  /*
   * Hook to call the api to pass the data
   * for d3 to generate the charts
   */
  componentDidMount() {
    this.getGimsReport(null);
    //this.generateCanvas();
  }

  /*
   * Function to call the api which
   * called by componentDidMount() hook
   */
  getGimsReport(filteredData) {
    //console.log("filtered data", filteredData);
    //let data = filteredData ? (filteredData.length > 0 ? { datasetId: filteredData } : {}) : {};
    let data = filteredData ? filteredData : {};
    data["report"] = true;
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.GIMS_REPORT_FROM_FILE,
      data,
      function (result) {
        if (result.error === "false" || result.error === false) {
          const gimsModelReports = result;
          //console.log("results ", result);
          // let errors = [];
          self.props.setDataForReportGeneration(data);
          self.loading = false;
          self.setState({
            gimsModelReports: gimsModelReports,
            showChart: true,
          });
        }
      }
    );
  }

  /*
   * Check the api data, if undefined or null
   * @return 0
   * else @return data
   */
  checkUndefinedOrNull(data) {
    if (data === undefined || data === null) {
      return 0;
    }
    return data;
  }

  /*
   * Generate ErrorTypes text based on
   * siteInfo condition
   */
  generateErrorTypeText(data) {
    const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    if (siteInfo.hasOwnProperty("ErrorTypes")) {
      let ErrorTypes = siteInfo.ErrorTypes;
      return ErrorTypes[data];
    }
    return data;
  }

  /*
   * Rendering the UI view
   */
  render() {
    // console.log("this.props", this.props.state);
    const { showChart, gimsModelReports } = this.state;
    //const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    //console.log("siteInfo", siteInfo);
    //console.log("current state", this.state);

    /*
     * Subcase count by Owner Start;p
     */
    const subCaseCountByOwner = [];
    if (gimsModelReports.hasOwnProperty("subcaseCountByOwnerTeam")) {
      let gimssubcaseCountOwner = gimsModelReports.subcaseCountByOwnerTeam;
      let subcaseOwnerKey = Object.keys(gimssubcaseCountOwner);
      let color = [
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      subcaseOwnerKey.map((data, i) => {
        return subCaseCountByOwner.push({
          errorTitle: data,
          count: gimssubcaseCountOwner[data],
          color: color[i],
        });
      });
      //console.log("subCaseCountByOwner", subCaseCountByOwner);
    }
    /*
     * Subcase count by Owner Ends
     */

    /*
     * Intake Channel count Start
     */
    const intakeChannelCount = [];
    if (gimsModelReports.hasOwnProperty("channelCount")) {
      let gimsChannelData = gimsModelReports.channelCount;
      let channelKeys = Object.keys(gimsChannelData);
      let color = [
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#E24D42",
        "#6ED0E0",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
        "#6ED0E0",
        "#7EB26D",
        "#EAB839",
        "#EF843C",
        "#EF843C",
        "#E24D42",
        "#987EAA",
        "#AA8787",
        "#6DB2A9",
        "#BFA4E2",
        "#7EB26D",
      ];
      channelKeys.map((data, i) => {
        return intakeChannelCount.push({
          errorTitle: data ? data : "Unknown",
          count: gimsChannelData[data],
          color: color[i],
        });
      });
      //console.log("channelKeys", intakeChannelCount);
    }
    /*
     * Intake Channel count End
     */

    //       function getTitle(title) {
    //         let data = '';
    //         if(title==='emptyAgentNotesCount'){
    //             data = 'Agentnotes Empty Count';
    //         }
    //         if(title==='agentNotesCount'){
    //             data = 'Agentnotes Count';
    //         }
    //         if(title==='mlCompletedCount'){
    //             data = 'ML Completed Count';
    //         }
    //         if(title==='manualCount'){
    //             data = 'Manual Count';
    //         }
    //         return data;
    //    }
    return (
      <div className="gims-charts">
        <div className="row">
          <div className="col-md-12">
            <GimsReportFilter getGimsFilteredReport={this.getGimsReport} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionThree"
                  checked={this.props.sectionThree}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionThree">
              <h6 className="mt-3">Sub-cases count by team assignment</h6>
              {showChart && (
                <GimsSubcaseCountOwnerTeam
                  id="subCaseCountByOwner"
                  data={subCaseCountByOwner}
                  width={1000}
                  height={300}
                  dataReports={this.state.gimsModelReports}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 chart-pad-left pad-adjust">
            {this.props.showChecked && (
              <span className="selectCheckbox">
                <input
                  type="checkbox"
                  name="sectionSix"
                  checked={this.props.sectionSix}
                  onChange={this.props.handleChange}
                />
              </span>
            )}
            <div className="col-md-12 chart-outline" id="sectionSix">
              <h6 className="mt-3">Intake Channel Type count</h6>
              {showChart && (
                <GimsIntakeChannelCount
                  id="channelCount"
                  data={intakeChannelCount}
                  width={450}
                  height={300}
                  dataReports={this.state.gimsModelReports}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*
 * GimsReportChartLayout propTypes checking
 * for checking props type
 */
GimsReportChartLayout.propTypes = {
  showChecked: PropTypes.bool,
  sectionSix: PropTypes.bool,
  sectionFive: PropTypes.bool,
  sectionFour: PropTypes.bool,
  handleChange: PropTypes.func,
  sectionThree: PropTypes.bool,
  sectionTwo: PropTypes.bool,
  sectionOne: PropTypes.bool,
  sectionSeven: PropTypes.bool,
  sectionEight: PropTypes.bool,
};

export default GimsReportChartLayout;
