import React, { Component } from "react";
import "./ScorecardReportCharts.css";
import * as d3 from "d3";
import PropTypes from "prop-types";

/*
 * A class to generate Gims scorecard count by Questionset
 */
export class ScorecardQuestionsetName extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(0.1);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Questionset");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardQuestionsetName proptypes
 */
ScorecardQuestionsetName.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by Questionset
 */
export class ScorecardCountByDataset extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
          "height",
          height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 180)
        );
      //.attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 180)}`)
      
      

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(0.2);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 250)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Dataset");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardCountByDataset proptypes
 */
ScorecardCountByDataset.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by Questionset
 */
export class ScorecardCountByAssociatedTeam extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(0.1);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Created by Team");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardCountByAssociatedTeam proptypes
 */
ScorecardCountByAssociatedTeam.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by Questionset
 */
export class ScorecardCountByCCAdmin extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(0.1);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Call Center Agent");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardCountByCCAdmin proptypes
 */
ScorecardCountByCCAdmin.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by Question status
 */
export class ScorecardQuestionstatus extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(0.1);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Question Status");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardQuestionsetName proptypes
 */
ScorecardQuestionstatus.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by Product
 */
export class ScorecardProduct extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(dynamicBars(sample));

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Product");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardProduct proptypes
 */
ScorecardProduct.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by Channel
 */
export class ScorecardChannel extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(dynamicBars(sample));

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Intake Channel Type");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardProduct proptypes
 */
ScorecardChannel.propTypes = {
  id: PropTypes.string,
};

/*
 * A class to generate Gims scorecard count by status
 */
export class ScorecardStatus extends Component {
  drawCharts(args) {
    let { width, height, data } = args ? args : this.props;
    //console.log("GimsReportcharts");
    let margin = { top: 10, right: 70, bottom: 70, left: 50 };

    var sample = data;
    //var margin = {top: 10, right: 70, bottom: 70, left: 50};
    d3.select(`#${this.props.id}`).selectAll("svg").remove();
    const svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr(
        "height",
        height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160)
      );

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(sample, (d) => d.count) + 10]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .call(d3.axisLeft(yScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          //    .style('fill-opacity', 1)
          .style("color", "black");
      });

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.errorTitle))
      .padding(dynamicBars(sample));

    chart
      .append("g")
      .attr("class", "opacityBlack")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .call((g) => {
        g.selectAll("text")
          .style("font-size", "10px")
          .style("font-weight", 600)
          // .style('fill-opacity', 1)
          .style("color", "black");
      })
      .selectAll(".tick text")
      .attr("y", 0)
      .attr("x", -10)
      .attr("dy", ".25em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "end");
    /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "toolTip")
      .style("display", "none");

    const barGroups = chart
      .selectAll()
      .data(sample)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        tooltip.text(d.count);
        return tooltip.style("display", "inline");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });

    barGroups
      .append("rect")
      .attr("x", (g) => xScale(g.errorTitle))
      .attr("y", (g) => yScale(g.count))
      .attr("height", (g) => height - yScale(g.count))
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => {
        return d.color;
      })
      .style("cursor", "pointer")
      .style("fill-opacity", 1);
    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
      .attr("y", (g) => yScale(g.count) - 3)
      .attr("text-anchor", "middle")
      .text((g) => `${g.count}`);

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.top)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Unique Count");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", 25)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("(Scorecard count)");

    svg
      .append("text")
      .attr("class", "xy-labels")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          " ," +
          (height + (sample.length === 0 ? 60 : 220)) +
          ")"
      )
      .style("text-anchor", "middle")
      .text("Status");
  }

  componentDidMount() {
    this.drawCharts(null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("next Props 2", nextProps);
    this.drawCharts(nextProps);
  }
  render() {
    return <div className="gims-data-chart" id={this.props.id}></div>;
  }
}

/*
 * ScorecardStatus proptypes
 */
ScorecardStatus.propTypes = {
  id: PropTypes.string,
};

function dynamicBars(data) {
  let len = data.length;
  let paddingData = 0.6;
  switch (len) {
    case 1:
      paddingData = 0.8;
      break;
    case 2:
      paddingData = 0.7;
      break;
    case 3:
      paddingData = 0.6;
      break;
    case 4:
      paddingData = 0.5;
      break;
    case 5:
      paddingData = 0.4;
      break;
    case 6:
      paddingData = 0.3;
      break;
    case 7:
      paddingData = 0.2;
      break;
    case 8:
      paddingData = 0.1;
      break;
    case 9:
      paddingData = 0.1;
      break;
    case 10:
      paddingData = 0.1;
      break;
    case 11:
      paddingData = 0.1;
      break;
    case 12:
      paddingData = 0.1;
      break;
    default:
      paddingData = 0.6;
      break;
  }
  // data.length > 6 ? 0.2 : 0.7;
  return paddingData;
}
